import React from "react"
import Layout from "../layouts/fr"
import "../style/legal.scss"

const remark = require('remark')
const remarkHtml = require('remark-html')

export default ({location, data}) => {
  const content = remark()
    .use(remarkHtml)
    .processSync(data.legal.frontmatter.body_fr).toString();
  return <Layout location={location}>
    <div className="row legal">
      <div className="offset-lg-2 col-lg-10 col-md-12">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  </Layout>
}

export const content = graphql`
  query {
    legal: markdownRemark(fileAbsolutePath: {regex: "/(pages/legal.md)/"}) {
      frontmatter {
        body_fr
      }
    }
  }
`
